import * as React from "react";
import { Zoom } from "react-slideshow-image";
import image1 from "./gallery/image1.jpg";
import image2 from "./gallery/image2.jpg";
import image3 from "./gallery/image3.jpg";
import image4 from "./gallery/image4.jpg";
import image5 from "./gallery/image5.jpg";
import image6 from "./gallery/image6.jpg";
import image7 from "./gallery/image7.jpg";
import image8 from "./gallery/image8.jpg";
import image9 from "./gallery/image9.jpg";
import image10 from "./gallery/image10.jpg";
import image11 from "./gallery/image11.jpg";
import image12 from "./gallery/image12.jpg";
import image13 from "./gallery/image13.jpg";
import image14 from "./gallery/image14.jpg";
import image15 from "./gallery/image15.jpg";
import image16 from "./gallery/image16.jpg";
import image17 from "./gallery/image17.jpg";
import image18 from "./gallery/image18.jpg";
import image19 from "./gallery/image19.jpg";
import image20 from "./gallery/image20.jpg";
import image21 from "./gallery/image21.jpg";
import image22 from "./gallery/image22.jpg";
import image23 from "./gallery/image23.jpg";
import image24 from "./gallery/image24.jpg";
import image25 from "./gallery/image25.jpg";
import image26 from "./gallery/image26.jpg";
import "react-slideshow-image/dist/styles.css";

export const Galerija: React.FC = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
  ];
  const zoomOutProperties = {
    duration: 3000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: true,
  };
  const Slideshow = () => {
    return (
      <div className="slide-container kurac">
        <Zoom {...zoomOutProperties}>
          {images.map((each, index) => (
            <img key={index} src={each} />
          ))}
        </Zoom>
      </div>
    );
  };
  return (
    <>
      <div className="App">
        <div className="row section-heading-wrapper">
          <div className="col-md-12 col-sm-12 text-center">
            <h2 className="section-heading">
              <span>Galerija</span>
            </h2>
            <p className="section-subheading">
              POGLEDAJTE NEKE OD NAŠIH FOTOGRAFIJA
            </p>
          </div>
        </div>
        <Slideshow />
      </div>
    </>
  );
};
