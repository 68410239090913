import * as React from "react";

export const Tim: React.FC = () => {
  return (
    <>
      <section className="page-header-tim">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3>Tim</h3>

              <p className="page-breadcrumb">
                <a href="/">Početna</a> / Tim
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-content-block section-our-team">
        <div className="container wow fadeInUp">
          <div className="row section-heading-wrapper">
            <div className="col-md-12 col-sm-12 text-center">
              <h2 className="section-heading">Naš tim</h2>
              <p className="section-subheading">UPOZNAJTE ČLANOVE NAŠEG TIMA</p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="team-layout-2 xs-margin">
                <figure className="team-member-2">
                  <a href="#" title="BOJAN VUČINIĆ">
                    <img src="images/BojanVucinic.jpg" alt="" />
                  </a>
                </figure>

                <h3>BOJAN VUČINIĆ</h3>
                <h4>Autor</h4>
                <p>
                  Specijalističke studije završio na Ekonomskom fakultetu
                  Univerziteta Crne Gore u Podgorici na smjeru Menadžment javnog
                  sektora. Više od 15 godina profesionalnog iskustva u medijskoj
                  industriji, televiziji, radiju i odnosima s javnošću. Urednik
                  i novinar u više medija u Crnoj Gori. Autor brojnih
                  televizijskih emisija i predavač na seminarima posvećenim
                  medijskoj pismenosti. Dobitnik nagrade za najbolju
                  televizijsku priču o procesu pristupanja Crne Gore Evropskoj
                  uniji ali i nagrade za najbolji istraživački prilog objavljen
                  u medijima na temu "Socijalna integracija Roma u Crnoj Gori".
                </p>

                <div className="team-social-share-2 clearfix">
                  <a
                    href="https://x.com/tumozukato1?t=_jjlSNk3BKcRXIzevMHmJw&s=09"
                    title="Twitter"
                  >
                    Twitter
                  </a>
                  <a
                    href="https://instagram.com/tumozukato?utm_source=qr&igshid=MzNlNGNkZWQ4Mg=="
                    title="Instagram"
                  >
                    Instagram
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="team-layout-2 xs-margin">
                <figure className="team-member-2">
                  <a href="#" title="DANILO PERIŠIĆ">
                    <img src="images/DaniloPerisic.jpg" alt="" />
                  </a>
                </figure>

                <h3>DANILO PERIŠIĆ</h3>
                <h4>Producent</h4>
                <p>
                  Diplomirao na smjeru Produkcija, Fakulteta dramskih umjetnosti
                  na Cetinju. Jedan od rijetkih producenata koji istovremeno ima
                  profesionalnu iskustvo u radu kao snimatelj, koje je sticao
                  radeći u više crnogorskih televizija. Njegov talenat prepoznat
                  je i na međunarodnim adresama, sarađivao je na različitim
                  projektima podržanim od strane američke ambasade u Podgorici,
                  Organizacije za evropsku bezbjednost i saradnju. Producent ili
                  koproducent više kratkih igranih filmova, dokumentarnih
                  emisija, radio drame.
                </p>

                <div className="team-social-share-2 clearfix">
                  <a
                    href="https://www.facebook.com/profile.php?id=100009429770587&mibextid=hIlR13"
                    title="Facebook"
                  >
                    Facebook
                  </a>
                  <a
                    href="https://instagram.com/danilo.perisic_?igshid=YzAwZjE1ZTI0Zg=="
                    title="Instagram"
                  >
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
