import * as React from "react";

export const NajnovijeVijesti: React.FC = () => {
  return (
    <>
      <section className="page-header-vesti">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3> Najnovije Vijesti</h3>

              <p className="page-breadcrumb">
                <a href="/">Početna</a> / Najnovije Vijesti
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <article className="post single-post">
                <div className="single-post-content">
                  <a title="">
                    <img alt="" src="images/seoski-turizam-vest.jpg" />
                  </a>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">Konkurs za najbolje u ruralnom turizmu</a>
                  </h2>

                  <p>
                    Ministarstvo turizma, ekologije, održivog i razvoja sjevera
                    pozvalo je registrovana seoska domaćinstva da se prijave na
                    nagradni konkurs za najbolje u ruralnom turizmu za ovu
                    godinu. Registrovana seoska domaćinstva mogu da se prijave
                    na nagradni konkurs koji se realizuje u četiri kategorije -
                    najbolje seosko domaćinstvo, najbolje seosko domaćinstvo na
                    katunu, najbolja seoska domaćica i najbolji mladi seoski
                    domaćin i domaćica.
                  </p>

                  <p>
                    Iz Ministarstva su saopštili da se konkurs održava u okviru
                    kampanje "Ruralno, održivo, domaćinski!". Rok za
                    dostavljanje prijava je 12. decembar do 14 sati.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
