import * as React from "react";

export const Kontakt: React.FC = () => {
  return (
    <>
      <section className="page-header-kontakt">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3>Kontakt</h3>

              <p className="page-breadcrumb">
                <a href="/">Početna</a> / Kontakt
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-content-block section-contact-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 wow fadeInRight">
              <div className="content-block">
                <h2 className="contact-title">Kontaktirajte nas</h2>

                <ul className="contact-info">
                  <li>
                    <span className="icon-container">
                      <i className="fa fa-home"></i>
                    </span>
                    <address>Podgorica</address>
                  </li>
                  <li>
                    <span className="icon-container">
                      <i className="fa fa-phone"></i>
                    </span>
                    <address>
                      <a href="tel:+38268898512">+38268898512</a>
                    </address>
                  </li>
                  <li>
                    <span className="icon-container">
                      <i className="fa fa-phone"></i>
                    </span>
                    <address>
                      <a href="tel:+38267099997">+38267099997</a>
                    </address>
                  </li>
                  <li>
                    <span className="icon-container">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <address>
                      <a href="mailto:djedovinacg@gmail.com">
                        djedovinacg@gmail.com
                      </a>
                    </address>
                  </li>
                  <li>
                    <span className="icon-container">
                      <i className="fa fa-globe"></i>
                    </span>
                    <address>
                      <a href="https://djedovinacg.me/">www.djedovinacg.me</a>
                    </address>
                  </li>
                </ul>

                <h2 className="contact-title">Društvene mreže</h2>

                <div className="social-icons margin-top-11 clearfix">
                  <a
                    title="Share at Facebook"
                    href="https://www.facebook.com/profile.php?id=100094573102351&mibextid=ViGcVu "
                    className="btn btn-social-icon"
                  >
                    <i
                      className="fab fa-facebook"
                      style={{ color: "#3b5998" }}
                    ></i>
                  </a>
                  <a
                    title="Tweet It"
                    href="https://x.com/djedovina?t=WsOH3Nu6OQShkelHzoBoYA&s=09"
                    className="btn btn-social-icon"
                  >
                    <i
                      className="fab fa-twitter"
                      style={{ color: "#55acee" }}
                    ></i>
                  </a>
                  <a
                    title="Share at Youtube"
                    href="https://youtube.com/@djedovinacg?si=f_n-yyTPMEngvrGs"
                    className="btn btn-social-icon "
                  >
                    <i
                      className="fab fa-youtube"
                      style={{ color: "#ed302f" }}
                    ></i>
                  </a>
                  <a
                    title="Share at Instagram"
                    href="https://instagram.com/djedovinacg?igshid=OTBlMGdoamE5YWZx"
                    className="btn btn-social-icon"
                  >
                    <i
                      className="fab fa-instagram"
                      style={{ color: "#ac2bac" }}
                    ></i>
                  </a>

                  <a
                    title="Share at Tiktok"
                    href="https://www.tiktok.com/@djedovina?_t=8hCK6LV8heP&_r=1"
                    className="btn btn-social-icon "
                  >
                    <i
                      className="fab fa-tiktok"
                      style={{ color: "#333333" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
